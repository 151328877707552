<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="d-flex flex-column form-user-update">
      <div class="top-message-block">
        <slot name="top-message-block"></slot>
      </div>
      <div class="form">
        <div class="row">
          <div class="col-sm-12">
            <b-form id="update-user-details-form" class="mt-4">
              <div role="group" class="from-group">
                <div class="logo-wrap">
                  <label for="file-upload">
                    <div>
                      <img class="preview img-fluid" v-if="avatarPreview" :src="avatarPreview"/>
                      <img class="img-fluid" v-else-if="avatar" alt="" :src="avatar"/>
                      <Avatar v-else/>
                    </div>
                  </label>
                  <input id="file-upload" class="d-none" type="file" @change="previewFiles"/>
                </div>
              </div>
              <div role="group" class="form-group-input">
                <label for="user-update-firstname">First name</label>
                <b-form-input
                    id="user-update-firstname"
                    v-model="form.firstname"
                    type="text"
                    :state="validateState('firstname')"

                ></b-form-input>
                <b-form-invalid-feedback id="input-firstname-feedback">
                  <div v-if="!$v.form.firstname.maxLength">Must not be at more 255 characters</div>
                  <div v-else-if="!$v.form.firstname.required">Cannot be empty</div>
                  <div v-else>The firstname is incorrect</div>
                </b-form-invalid-feedback>
              </div>
              <div role="group" class="form-group-input mt-3">
                <label for="user-update-lastname">Last name</label>
                <b-form-input
                    id="user-update-lastname"
                    v-model="form.lastname"
                    type="text"
                    :state="validateState('lastname')"

                ></b-form-input>
                <b-form-invalid-feedback id="input-firstname-feedback">
                  <div v-if="!$v.form.lastname.maxLength">Must not be at more 255 characters</div>
                  <div v-else-if="!$v.form.lastname.required">Cannot be empty</div>
                  <div v-else>The lastname is incorrect</div>
                </b-form-invalid-feedback>
              </div>
              <div role="group" class="form-group-input mt-3">
                <label for="user-update-email">Email</label>
                <b-form-input
                    id="user-update-email"
                    v-model="form.email"
                    disabled
                    type="text"
                    :state="validateState('email')"
                ></b-form-input>
                <b-form-invalid-feedback id="input-firstname-feedback">
                  Email address is incorrect
                </b-form-invalid-feedback>
              </div>
              <div role="group" class="form-group-input mt-3">
                <label for="user-update-phone-number">Phone number</label>
                <b-form-input
                    id="user-update-phone-number"
                    v-model="form.phone_number"
                    type="text"
                    :state="validateState('phone')"

                ></b-form-input>
                <b-form-invalid-feedback id="input-firstname-feedback">
                  Phone number is incorrect
                </b-form-invalid-feedback>
              </div>
              <div class="header-password-wrap">
                <div class="header-password">Change password</div>
                <hr/>
              </div>
              <div role="group" class="form-group-input mt-3">
                <label for="user-current-password">Current Password</label>
                <b-form-input id="user-current-password"
                              autocomplete="new-password"
                              v-model="form.password_current"
                              @focus="$v.form.password_current.$reset()"
                              @blur="$v.form.password_current.$touch()"
                              type="password"
                              :state="validateState('password_current')"/>
                <b-form-invalid-feedback id="input-password-feedback">
                  <div v-if="!$v.form.password_current.maxLength || !$v.form.password_current.minLength">Must not be at less 8 and more 255 characters</div>
                  <div v-else-if="!$v.form.password_current.required">This field is required</div>
                  <div v-else-if="!$v.form.password_current.check_password">This password is invalid</div>
                  <div v-else>The password is incorrect</div>
                </b-form-invalid-feedback>
              </div>
              <div role="group" class="form-group-input mt-3">
                <label for="user-update-password">New Password</label>
                <b-form-input id="user-update-password"
                              v-model="form.password"
                              type="password"
                              @blur="$v.form.password.$touch()"
                              :state="validateState('password')" />
                <b-form-invalid-feedback id="input-password-feedback">
                  <div v-if="!$v.form.password.maxLength || !$v.form.password.minLength">Must not be at less 8 and more 255 characters</div>
                  <div v-else-if="!$v.form.password.required">This field is required</div>
                  <div v-else>The password is incorrect</div>
                </b-form-invalid-feedback>
              </div>
              <div role="group" class="form-group-input mt-3">
                <label for="user-update-password-confirm">Confirm Password</label>
                <b-form-input id="user-update-password-confirm"
                              v-model="form.password_confirm"
                              type="password"
                              @blur="$v.form.password_confirm.$touch()"
                              :state="validateState('password_confirm')" />
                <b-form-invalid-feedback id="input-password-confirm-feedback">
                  <div v-if="!$v.form.password_confirm.sameAsPassword">
                    Password must be identical
                  </div>
                  <div v-else>
                    The password is incorrect
                  </div>
                </b-form-invalid-feedback>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapActions, mapGetters } from "vuex";
import {email, maxLength, required, minLength, sameAs, requiredIf} from "vuelidate/lib/validators";
import Avatar from "@/components/icons/Avatar";

export default {
  name: "UpdateUserDetailsForm",
  data () {
    return {
      avatarPreview: null,
      loading: false,
      form: {
        avatar: null,
        firstname: null,
        lastname: null,
        email: null,
        phone_number: null,
        password: null,
        password_confirm: null,
        password_current: null
      }
    };
  },
  components: { Avatar,  },
  mixins: [validationMixin],
  watch: {
    'form.password': function (val) {
      if (val.length === 0) {
        this.form.password_confirm = '';
      }
    }
  },
  validations: {
    form: {
      firstname: {
        required, maxLength: maxLength(255)
      },
      lastname: {
        required, maxLength: maxLength(255)
      },
      email: {
        required, email, maxLength: maxLength(255)
      },
      password: {
        maxLength: maxLength(255),
        minLength: minLength(8),
        required: requiredIf(function() {
          return this.form.password_current;
        })
      },
      password_current: {
        maxLength: maxLength(255),
        minLength: minLength(8),
        required: requiredIf(function() {
          return this.form.password;
        }),
        check_password(value) {
          const passwordCurrent = this.$v.form.password_current;

          if (passwordCurrent.$dirty && passwordCurrent.required && passwordCurrent.maxLength && passwordCurrent.minLength) {
            return this.checkPassword(value);
          }
          return true;
        }
      },
      password_confirm: {
        sameAsPassword: sameAs(function () {
          return this.form.password;
        })
      },
      phone: {
        minLength: minLength(8)
      }
    }
  },
  validateState (name) {
    const { $dirty, $error } = this.$v.form[name];
    return $dirty ? !$error : null;
  },
  computed: {
    ...mapGetters('user/position', ['listPositions']),
    ...mapGetters("auth/user", ['position', 'user', 'profile']),
    ...mapGetters({
      avatar: 'auth/user/avatar'
    }),
    isPositionInvalid: function () {
      let validation = this.validateState('position');
      return validation === null ? false : !validation;
    }
  },
  methods: {
    ...mapActions('auth/user', ['getAvatar', 'updateUser', "uploadAvatar"]),
    ...mapActions('auth', ['checkPassword']),
    ...mapActions('notifications', ['addNotification']),
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    previewFiles (e) {
      const file = e.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      const that = this;
      fileReader.addEventListener("load", function () {
        that.avatarPreview = this.result;
      });
      this.form.avatar = file;
    },
    submit () {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.loading = true;
      this.updateUser(this.form).then((isUserUpdated) => {
        this.loading = false;
        this.$emit('close');
        if (isUserUpdated) {
          this.addNotification({
            title: 'User details',
            message: 'The user details has been updated',
            type: 'success'
          })
        }
      });
      if (this.form.avatar !== null) {
        this.uploadAvatar(this.form.avatar);
      }
    }
  },
  mounted () {
    this.form = {
      ...this.form,
      firstname: this.profile ? this.profile.firstname : '',
      lastname: this.profile ? this.profile.lastname : '',
      email: this.user.email,
      phone_number: this.user.phone_number,
      position: this.position
    };
  }
}
</script>

<style scoped>

</style>