<template>
  <div class="notifications-list">
    <b-overlay>
      <div v-if="notifications.length" class="notifications">
        <div v-for="notification in notifications" :key="`notification-${notification.id}`">
          <div @click="() => readMessageHandler(notification)" :class="notification.read_at ? '' : 'unread'" class="notification-item">
            <div class="user-info" :data-user-id="notification.author && notification.author.user && notification.author.user.id">
              <div class="avatar" :data-user-id="notification.author_id">
                {{ notification.author && notification.author.user && fetchAvatar(notification.author.user.id) && '' }}
                <b-avatar v-if="notification.author && notification.author.user && getAvatar(notification.author.user.id)"
                          :src="notification.author && notification.author.user && getAvatar(notification.author.user.id)">
                </b-avatar>
                <Avatar v-else-if="!isLoadingAvatar(notification.author && notification.author.user && notification.author.user.id)"/>
              </div>
            </div>
            <div class="notification-content">
              <div class="message" v-html="notification.message"></div>
              <div class="datetime">{{ notification.created_at|date_format('HH:mm on DD/MM/YYYY') }}</div>
            </div>
            <b-dropdown dropleft class="actions">
              <template #button-content>
                <ThreeDotsIcon />
              </template>
              <b-dropdown-item @click="() => handleRead(notification.id)" v-if="notification.read_at === null">
                Mark as read
              </b-dropdown-item>
              <b-dropdown-item @click="() => handleUnread(notification.id)" v-else>
                Mark as unread
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Avatar from "@/components/icons/Avatar";
import ThreeDotsIcon from "../../components/icons/ThreeDotsIcon";

export default {
  name: "Notifications",
  components: {ThreeDotsIcon, Avatar},
  props: {
    unread: Number
  },
  methods: {
    ...mapActions("pageNotifications", ["fetchNotifications", "selectNotification", "selectAllNotification",
      "clearNotifications", "stopWatching", "startWatching", "markAsRead", "selectClient", "paginate", "clear"]),
    ...mapActions("user/avatars", ["fetchAvatar"]),
    handleRead(notificationId) {
      this.markAsRead({id: notificationId, type: 'read'});
    },
    handleUnread(notificationId) {
      this.markAsRead({id: notificationId, type: 'unread'});
    },
    readMessageHandler(notification) {
      if (notification.read_at === null) {
        this.handleRead(notification.id);
      }
    }
  },
  computed: {
    ...mapGetters("pageNotifications", ["notifications", "markAsReadLoading", "watchingLoading", "hasSelectedNotification",
      "curPagination", "isSelectedRow", "isLoading"]),
    ...mapGetters("user/avatars", ["getAvatar", "isLoadingAvatar"])
  },
  watch: {
    unread(val) {
      this.clear();
      this.fetchNotifications({unread : val});
    }
  },
  mounted() {
    this.fetchNotifications({unread: this.unread});

    document.getElementsByClassName('panel-content')[0].onscroll = (e) => {
      if (e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) < 100
          && Math.ceil(this.curPagination.totalRows / this.curPagination.perPage) > this.curPagination.currentPage
          && e.target.scrollTop > 0 && this.isLoading === false) {
        this.fetchNotifications({unread: this.unread});
      }
    }
  },
}
</script>

<style scoped>

</style>