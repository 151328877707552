<template>
  <div></div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Seo",
  computed: {
    ...mapGetters('auth/user/claim', ["selectedClaim"]),
    ...mapGetters('auth/user/client', ["selectedClient"]),
    ...mapGetters('preview/file', ["file", "claim"])
  },
  watch: {
    selectedClaim(val) {
      if (val) {
        switch (this.$route.name) {
          case 'documents':
            document.title = `${val.name} | Documents`;
            break;
          case 'final-claim-pack':
            document.title = `${val.name} | Final Claim Pack`;
            break;
          case 'users':
            document.title = `Users | ${this.selectedClient.name}`;
            break;
        }
      }
    },
    file(val) {
      if (val && this.$route.name === 'preview') {
        document.title = `${this.file.name} | ${this.claim.name}`;
      }
    }
  },
  mounted() {
    if (this.selectedClaim) {
      switch (this.$route.name) {
        case 'documents':
          document.title = `${this.selectedClaim.name} | Documents`;
          break;
        case 'final-claim-pack':
          document.title = `${this.selectedClaim.name} | Final Claim Pack`;
          break;
        case 'users':
          document.title = `Users | ${this.selectedClient.name}`;
          break;
      }
    }
  }
}
</script>

<style scoped>

</style>