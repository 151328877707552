<template>
    <Toast ref="toast"></Toast>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";
import Toast from "./Toast";

export default {
    name: "ErrorHandler",
    components: {
        Toast
    },
    computed: {
        ...mapGetters('errors', ['errors'])
    },
    methods: {
        ...mapActions('errors', ['clearErrors'])
    },
    watch: {
        errors: function (val) {
            if (!_.isEmpty(val)) {
                const errors = _.cloneDeep(val);
                const toast = this.$refs.toast;
                errors.forEach(function (error) {
                    let errorShowed = false;
                    if (error.errors) {
                        for (let key in error.errors) {
                            const item = error.errors[key];
                            if (Array.isArray(item) && item.length > 0) {
                                item.forEach(erText => {
                                    errorShowed = true;
                                    toast.showError({
                                        message: erText,
                                        title: error.title
                                    });
                                })
                            }
                        }
                    }

                    if (errorShowed === false) {
                        toast.showError(error)
                    }
                });
                this.clearErrors();
            }
        }
    }
}
</script>

<style scoped>

</style>