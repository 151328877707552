<template>
  <Toast ref="toast"></Toast>
</template>

<script>
import Toast from "./Toast";
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";

export default {
  name: "NotificationHandler",
  components: {Toast},
  computed: {
    ...mapGetters('notifications', ['notifications'])
  },
  methods: {
    ...mapActions('notifications', ['clearNotifications'])
  },
  watch: {
    notifications: function (val) {
      if (!_.isEmpty(val)) {
        const notifications = _.cloneDeep(val);
        const toast = this.$refs.toast;
        notifications.forEach(function (item) {
          toast.showNotification(item)
        });
        this.clearNotifications();
      }
    }
  }
}
</script>

<style scoped>

</style>