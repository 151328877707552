<template>
<div class="d-none">

</div>
</template>

<script>
export default {
  name: "MsgBox",
  props: {
    message: {
      type: String,
      required: true
    },
    okTitle: {
      type: String,
      required: true
    },
    cancelTitle: {
      type: String,
      default: 'Cancel'
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    show() {
      return this.$bvModal.msgBoxConfirm(this.message, {
        title: this.title,
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.okTitle,
        cancelTitle: this.cancelTitle,
        cancelVariant: 'secondary cancel',
        footerClass: 'p-2',
        contentClass: 'confirm-dialog',
        hideHeaderClose: false,
        headerCloseContent: '<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M5.91615 5.00007L9.80995 1.10608C10.0633 0.852787 10.0633 0.443255 9.80995 0.189966C9.55667 -0.0633221 9.14714 -0.0633221 8.89386 0.189966L4.99994 4.08396L1.10614 0.189966C0.85274 -0.0633221 0.443335 -0.0633221 0.190051 0.189966C-0.0633505 0.443255 -0.0633505 0.852787 0.190051 1.10608L4.08385 5.00007L0.190051 8.89407C-0.0633505 9.14736 -0.0633505 9.55689 0.190051 9.81018C0.316278 9.93653 0.482247 10 0.648097 10C0.813947 10 0.979797 9.93653 1.10614 9.81018L4.99994 5.91618L8.89386 9.81018C9.0202 9.93653 9.18605 10 9.3519 10C9.51775 10 9.6836 9.93653 9.80995 9.81018C10.0633 9.55689 10.0633 9.14736 9.80995 8.89407L5.91615 5.00007Z" fill="#212721"/>\n' +
            '</svg>\n',
        centered: true
      })
    }
  }
}
</script>
