<template>
  <div class="d-flex flex-column menu">
    <div class="menu-group first-group">
      <div class="group-title bordered">
        <b-dropdown :text="client | truncate(23)">
          <b-dropdown-item
              :style="clientDropdownStyles"
              v-for="client in clients"
              :key="client.id"
              v-on:click="selectClientHandler(client)"
              :title="client.name">
            {{ client.name | truncate(20) }}
            <span v-if="selectedClient && client.id === selectedClient.id" class="float-right">
              <CheckIcon />
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="group-title bordered">
        <b-dropdown :disabled="selectedClient === null" :text="claim | truncate(23)">
          <b-dropdown-item
              :style="claimDropdownStyles"
              v-for="claim in claims"
              :key="claim.id"
              v-on:click="selectClaimHandler(claim)"
              :title="claim.name">
            {{ claim.name  | truncate(23)}}
            <span v-if="selectedClaim && claim.id === selectedClaim.id" class="float-right">
              <CheckIcon />
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="menu-item first-item mt-4" :class="{active: isActiveItem('documents')}">
      <router-link
          to="/documents"
          class="link"
          v-if="!isDocumentPageDisabled"
      >Documents</router-link>
      <span class="link disabled" disabled="disabled" v-if="isDocumentPageDisabled">Documents</span>
    </div>
    <div v-if="canViewFinalClaimPack" class="menu-item" :class="{active: isActiveItem('final-claim-pack')}">
      <router-link to="/final-claim-pack" class="link" v-if="!isPageDisabled">Final claim pack</router-link>
      <span class="link disabled" disabled="disabled" v-if="isPageDisabled">Final claim pack</span>
    </div>

    <div class="menu-item hr">
      <hr>
    </div>

    <div class="menu-group" v-if="hasOnePermission(['manage_users'])">
      <div class="group-title">
        Administration
      </div>
    </div>
    <div class="menu-item first-item" :class="{active: isActiveItem('users')}" v-if="hasOnePermission(['manage_users'])">
      <router-link to="/users" class="link">Users</router-link>
    </div>
    <div class="menu-item hr" v-if="hasOnePermission(['manage_users'])">
      <hr>
    </div>


    <div class="menu-group">
      <div class="group-title">
        Useful Links
      </div>
    </div>
    <div class="menu-item first-item">
        <a class="link" href=" https://forrestbrown.co.uk/myfb-faqs/" target="_blank">FAQs</a>
    </div>
    <div class="menu-item">
      <a class="link" href="https://forrestbrown.co.uk/knowledge-bank/" target="_blank">KnowledgeBank</a>
    </div>
    <div class="menu-item">
      <a class="link" href="https://forrestbrown.co.uk/news/" target="_blank">News</a>
    </div>
    <div class="menu-item">
      <a class="link" href="https://forrestbrown.co.uk/who-we-are/our-people/" target="_blank">The ForrestBrown team</a>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import CheckIcon from "../../components/icons/CheckIcon";

export default {
  name: "LeftSideMenu",
  components: {
    CheckIcon,
  },
  data() {
      return {}
  },
  computed: {
    ...mapGetters('auth/user/client', ['clients', 'selectedClient']),
    ...mapGetters('auth/user/claim', ['claims', 'selectedClaim']),
    ...mapGetters('auth/user', ['canUploadDocuments', 'canReadDocuments', 'canViewFinalClaimPack', 'user', 'userPermissions', ]),
    ...mapGetters('messages', ['countUnreadMessages']),
    client: function () {
      return  _.isEmpty(this.selectedClient) ? ' - select organisation' : this.selectedClient.name;
    },
    claim: function () {
      return _.isEmpty(this.selectedClaim) ? ' - select claim' : this.selectedClaim.name
    },
    isPageDisabled() {
      return _.isEmpty(this.selectedClaim)
    },
    isDocumentPageDisabled() {
      if (this.isPageDisabled) {
        return true;
      }
      if (!this.canReadDocuments && !this.canUploadDocuments) {
        return true
      }
      return false;
    },
    clientDropdownStyles() {
      return {
        'width': '17.5rem',
      }
    },
    claimDropdownStyles() {
      return {
        'width': '17.5rem',
      }
    },
  },
  methods: {
    ...mapActions('auth/user/client', ['selectClient', ]),
    ...mapActions('auth/user/claim', ['fetchClaims', 'selectClaim', ]),
    ...mapActions('finalClaimPack', ['clearSelectedDocuments', ]),
    hasOnePermission(permissions) {
      if (this.user === null)
        return false;

      for (const permission of permissions) {
        if (this.userPermissions.indexOf(permission) > -1)
          return true;
      }

      return false;
    },
    isActiveItem(page) {
      return this.$router.currentRoute.name === page;
    },
    selectClientHandler(client) {
      this.selectClient(client);
      this.selectClaim(null);
      this.fetchClaims(client.id);
    },
    selectClaimHandler(claim) {
      this.selectClaim(claim);
      this.clearSelectedDocuments();
    },
  },
  watch: {
    selectedClient(client) {
      if (client)
        this.fetchClaims(client.id)
    },
  }
}
</script>

<style scoped>

</style>
