<template>
  <img src="/avatar.png" alt="" style="height: 40px; width: 40px; border-radius: 50%;" />
</template>

<script>
export default {
name: "Avatar"
}
</script>

<style scoped>

</style>