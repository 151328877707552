<template>
  <div>
    <b-toast id="toast" variant="warning" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-img blank blank-color="#ff5555" class="mr-2" width="12" height="12"></b-img>
          <strong class="mr-auto">Notice!</strong>
          <small class="text-muted mr-2">42 seconds ago</small>
        </div>
      </template>
      This is the content of the toast.
      It is short and to the point.
    </b-toast>
  </div>
</template>

<script>

export default {
  name: "Toast",
  methods: {
    showError(error) {
      this.showNotification({
        title: error.title,
        message: error.message,
        type: 'danger'
      })
    },
    showNotification(data) {
      const h = this.$createElement

      // Create the message
      const vNodesMsg = data.message;

      // Create the title
      const vNodesTitle = h(
          'div',
          { class: ['d-flex', 'flex-grow-1', 'align-items-center', 'mr-2'] },
          [
            h('span', { class: 'mr-2 icon' }, ''),
            h('span', { class: 'mr-2' }, data.title)
          ]
      )

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: [vNodesTitle],
        solid: true,
        variant: data.type,
        autoHideDelay: 3000
      })
    }
  }
}
</script>

<style scoped>

</style>