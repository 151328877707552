<template>

  <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.40927 14.1507L2.46979 14.1247V14.0588V9.50371C2.46979 4.32659 6.73208 0.1 12 0.1C17.2679 0.1 21.5302 4.32659 21.5302 9.50371V14.0588V14.1247L21.5907 14.1507C22.8861 14.7073 23.9 15.7316 23.9 17.1564C23.9 19.0866 22.2819 20.7 20.2969 20.7H3.70312C1.7181 20.7 0.1 19.0866 0.1 17.1564C0.1 15.7316 1.11386 14.7073 2.40927 14.1507ZM19.0635 9.50356L19.0634 9.40371H19.0623C19.0094 5.53718 15.8905 2.5 11.9995 2.5C8.07501 2.5 4.93595 5.58967 4.93595 9.50371C4.93595 9.98195 4.93468 10.4632 4.93341 10.9453C4.93006 12.2194 4.92669 13.4999 4.94663 14.7485L4.94657 14.7485L4.94674 14.7519C4.98107 15.4379 4.41863 16.0097 3.79643 16.01C3.43451 16.0102 3.12629 16.1262 2.90768 16.3313C2.68864 16.5368 2.5664 16.8254 2.5664 17.1564C2.5664 17.8232 3.06093 18.3 3.70285 18.3H20.2967C20.9386 18.3 21.4331 17.8232 21.4331 17.1564C21.4331 16.8254 21.3109 16.5368 21.0918 16.3313C20.8731 16.1262 20.5647 16.0102 20.2025 16.0101C19.5799 16.0098 19.017 15.4365 19.0527 14.7495L19.0528 14.7495L19.0529 14.745C19.0649 13.0509 19.0662 11.2302 19.0635 9.50356ZM8.46033 23.8524C8.69145 23.627 9.00543 23.5 9.33333 23.5H14.6667C14.9946 23.5 15.3086 23.627 15.5397 23.8524C15.7707 24.0776 15.9 24.3826 15.9 24.7C15.9 25.0174 15.7707 25.3224 15.5397 25.5476C15.3086 25.773 14.9946 25.9 14.6667 25.9H9.33333C9.00543 25.9 8.69145 25.773 8.46033 25.5476C8.22931 25.3224 8.1 25.0174 8.1 24.7C8.1 24.3826 8.22931 24.0776 8.46033 23.8524Z" fill="white" stroke="#316579" stroke-width="0.2"/>
  </svg>


</template>

<script>

export default {
  name: 'BellIcon',
}

</script>
