<template>
  <div class="d-flex h-100 align-items-center">
    <RightSidePanel v-if="isShowUserDetails" ref="showUserDetails"
                    v-on:click-action="rightSidePanelClickActionHandler">
      <template v-slot:title>
        <span>Update your details</span>
      </template>
      <template v-slot:content>
        <UpdateUserDetailsForm @close="closeUpdateDetails" ref="updateUserDetailForm"/>
      </template>
      <template v-slot:action-title>
        <span>Save changes</span>
      </template>
    </RightSidePanel>
    <div class="title flex-grow-1">
      <slot name="title"></slot>
    </div>
    <div id="notifications-bar">
      <div class="notifications-link" @click="showNotifications">
        <div class="bell">
          <BellIcon></BellIcon>
          <div class="red-circle" v-if="countUnreadNotifications > 0"></div>
        </div>
      </div>
    </div>
    <RightSidePanel v-if="isShowNotifications" ref="notifications" :isFooterShown="false" :isFooter="false">
      <template v-slot:title>
        <b-dropdown class="notification-title">
          <template #button-content>
            <span class="title-notification" :class="{'active': isUnread}">Notifications</span>
          </template>
          <b-dropdown-item :class="{active: isUnread}" @click="unreadNotificationsHandle">
            <div>Unread</div>
          </b-dropdown-item>
          <b-dropdown-item :class="{active: !isUnread}" @click="allNotificationsHandle">
            <div>
              All updates
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <b-button size="sm" :disabled="markAsReadLoading" @click="markAsReadHandle" variant="primary">Mark all as read</b-button>
      </template>
      <template v-slot:content>
        <Notifications :unread="unread"/>
        <b-overlay v-if="isLoadingNotifications" :show="isLoadingNotifications" rounded="sm" class="p-4"></b-overlay>
      </template>
      <template v-slot:action-title>
      </template>
    </RightSidePanel>
    <b-dropdown ref="dropdownProfile" class="profile" size="lg" variant="link" toggle-class="text-decoration-none"
                no-caret>
      <template #button-content>
        <div class="d-flex align-items-center" v-if="user">
          <div class="d-flex flex-column justify-content-between">
            <div class="login text-right">{{ username || '' }}</div>
            <div class="text-right position">{{ position && position.name }}</div>
          </div>
          <div class="login-wrap">
            <b-avatar v-if="avatar" :src="avatar"></b-avatar>
            <Avatar v-else/>
          </div>
        </div>
      </template>
      <b-dropdown-item @click="updateDetailsHandler">Update details</b-dropdown-item>
      <b-dropdown-item @click="logoutHandler">Logout</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Avatar from "@/components/icons/Avatar";
import UpdateUserDetailsForm from "@/pages/parts/users/UpdateUserDetailsForm";
import RightSidePanel from "@/layouts/workspace/RightSidePanel";
// import Logout from "../../components/Logout";
import BellIcon from "../../components/icons/BellIcon";
import Notifications from "./Notifications";

export default {
  name: "TopBar",
  data: () => {
    return {
      isShowUserDetails: false,
      isShowNotifications: false,
      unread: 0
    }
  },
  components: {RightSidePanel, UpdateUserDetailsForm, Avatar, BellIcon, Notifications},
  computed: {
    ...mapGetters("auth/user", ["username", "position", "user",]),
    ...mapGetters("pageNotifications", ["countUnreadNotifications", 'lastUnreadNotificationsCheck', 'markAsReadLoading', 'isUnread']),
    ...mapGetters({isLoadingNotifications: "pageNotifications/isLoading"}),
    ...mapGetters({
      avatar: 'auth/user/avatar'
    })
  },
  mounted() {
    // check for notifications only once per minute
    if ((Date.now() / 1000 - this.lastUnreadNotificationsCheck) > 60) {
      this.fetchCountUnreadNotifications();
    }
  },
  methods: {
    ...mapActions('pageNotifications', ["fetchCountUnreadNotifications", "fetchNotifications", "clear", "markAsReadAll"]),
    ...mapActions("auth", {'logout': 'logout'}),

    updateDetailsHandler() {
      this.isShowUserDetails = true;
      setTimeout(() => {
        this.$refs.showUserDetails.show();
      });
    },
    closeUpdateDetails() {
      this.isShowUserDetails = false;
      this.$refs.showUserDetails.hide();
    },
    showNotifications() {
      this.isShowNotifications = true;
      setTimeout(() => {
        this.$refs.notifications.show();
      })
    },
    rightSidePanelClickActionHandler() {
      this.$refs.updateUserDetailForm.submit();
    },
    logoutHandler() {
      this.clear();
      this.logout().then(() => {
        this.$router.push('/login').catch(() => {});
      });

    },
    unreadNotificationsHandle() {
      this.unread = 1;
    },
    allNotificationsHandle() {
      this.unread = 0;
    },
    markAsReadHandle() {
      this.markAsReadAll();
    }
  }
}
</script>

<style scoped>

</style>