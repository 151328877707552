<template>
  <div class="container-fluid d-flex px-0 workspace h-100">
    <div class="left-side shadow">
      <div class="logo">
        <Logo/>
      </div>
      <LeftSideMenu></LeftSideMenu>
    </div>
    <div class="right-side flex-grow-1 d-flex flex-column">

      <div class="top-bar">
        <slot name="top-bar"></slot>
      </div>

      <slot name="subheader"></slot>

      <slot name="content" v-if="showContent"></slot>
      <template v-else>
        <div class="content flex-grow-1">
          <div class="row h-100">
            <div class="col my-auto">
              <h4 class="text-center">
                Please select a Client and Claim in order to proceed.
              </h4>
            </div>
          </div>
        </div>

      </template>

      <slot name="panel"></slot>

    </div>

    <ErrorHandler></ErrorHandler>
    <NotificationHandler></NotificationHandler>
    <Seo />
    <MsgBox ref="timedOutMsgBox"
            :cancel-title=null
            message="You have been timed out due to inactivity. Please login."
            ok-title="Login" title="Time out"/>
  </div>
</template>

<script>
import LeftSideMenu from "./workspace/LeftSideMenu";
import ErrorHandler from "../components/ErrorHandler";
import NotificationHandler from "../components/NotificationHandler";
import {mapActions, mapGetters} from "vuex";
import Logo from "../components/Logo";
import Seo from "./workspace/Seo";
import MsgBox from "@/components/MsgBox";

export default {
  name: "Workspace",
  components: {
    LeftSideMenu, ErrorHandler, NotificationHandler, Logo, Seo, MsgBox
  },
  computed: {
    ...mapGetters('auth/user', ['user']),
    ...mapGetters('auth/user/client', ['clients', 'selectedClient']),
    ...mapGetters('auth/user/claim', ['selectedClaim']),
    ...mapGetters('user/position', ['listPositions']),
    ...mapGetters('auth', ['isShowLoginPopup']),
    showContent() {
      return (this.selectedClient && this.selectedClaim) || (this.$route.name === 'users');
    },
  },
  watch: {
    isShowLoginPopup(val) {
      if (val) {
        this.$refs.timedOutMsgBox.show()
            .then(() => {
              this.showLoginPopup(false);
              this.$router.push('/login').catch(() => {});
            });
      }
    }
  },
  mounted() {
    if (this.user === null)
      this.fetchUser();

    if (this.listPositions.length === 0)
      this.fetchPositions();

    if (this.clients.length === 0)
      this.fetchClients();

  },
  methods: {
    ...mapActions('auth/user', ['fetchUser']),
    ...mapActions('auth/user/client', ['fetchClients']),
    ...mapActions('user/position', ['fetchPositions']),
    ...mapActions("auth", ["logout", "refreshToken", "clear", "showLoginPopup"]),
    logoutHandler() {
      this.logout();
    }
  }
}
</script>

<style scoped>

</style>